#modal {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: fixed;
  color: #f5f5f7;
  background-color: rgba(16, 16, 21, 0.8);;
  display: flex;
  padding-top: 2rem;
  max-width: 30rem;
  flex-direction: column;
  top: 0;
}

.modal--close-section {
  display: flex;
  justify-content: end;
  margin: 1rem 1rem 0 0;
}

.modal--close {
  padding: 0.75rem;
  cursor: pointer;
  line-height: 0;
}

.modal--close-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.modal--view {
  display: flex;
  justify-content: center;
  background: #000;
  margin: 0px 1rem;
  border-radius: 1rem;
  padding: 2rem;
}
