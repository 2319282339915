/* ---------------------- */
/* Fonts                  */
/* ---------------------- */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

/* ---------------------- */
/* Custom properties      */
/* ---------------------- */

:root {
  --clr-light-heading: #f5f5f5;
  --clr-light-text: #dcdcdc;

  --clr-background-darker: #161619;
  --clr-background-dark: #1a1b21;
  --clr-background-light: #dddddd;

  --clr-social-icons: #918e9b;
  --clr-accent: #f3bf99;

  --clr-text-light: #f5f5f7;
  --clr-text-dark: #1d1d1f;
  --clr-text-grey: #5c5c5f;

  --clr-button-bg: #112a59;
  --clr-linkedin: #5093e2;
  --clr-facebook: rgb(24, 119, 242);

  --ff-main: "Inter", sans-serif;
}

/* ---------------------- */
/* Reset                  */
/* ---------------------- */

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--clr-button-bg);
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  display: block;
  width: 100%;
}

/* remove animations for people who've turned them off */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* ---------------------- */
/* Typography             */
/* ---------------------- */

html {
  font-family: var(--ff-main);
}

a {
  text-decoration: none;
}

p {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 150%;
}

/* ---------------------- */
/* Buttons                */
/* ---------------------- */

.profile--photo-section {
  display: flex;
  justify-content: center;
  margin-top: -5rem;
}

.info--profile-photo {
  height: 10rem;
  width: auto;
  border-radius: 100%;
  border: 4px solid #b3965e;
}

.info--buttonsSection {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
}

.info--SocialSection {
  width: 100%;
  margin-top: 3rem;
  padding: 0rem 1rem;
}

.info--SocialSectionBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 1rem;
  padding: 1rem;
}

.info--buttonsSocialSection {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
}

.info--item {
  width: 33.3%;
}

.info--button {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info--buttonSocial {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button--info {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 1rem;
  line-height: 0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  cursor: pointer;
  color: var(--clr-text-light);
  background-color: var(--clr-button-bg);
  border: 1px solid var(--clr-button-bg);
}

.button--facebook {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 1rem;
  line-height: 0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  cursor: pointer;
  color: var(--clr-text-light);
  background-color: var(--clr-facebook);
  border: none;
}

.button--instagram {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 1rem;
  line-height: 0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  cursor: pointer;
  color: var(--clr-text-light);
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  border: none;
}

.button--linkedin {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 1rem;
  line-height: 0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  cursor: pointer;
  color: var(--clr-text-light);
  background-color: var(--clr-linkedin);
  border: none;
}

.button:hover {
  filter: brightness(85%);
}

.info--text {
  margin: 0.5rem 0 0;
  font-size: 0.9rem;
}

/*.button--email {
  border: 1px solid var(--clr-light-heading);
}*/

/*.button--linkedin {
  color: var(--clr-light-heading);
  background-color: var(--clr-linkedin);
  border: 1px solid var(--clr-linkedin);
}*/

/* ---------------------- */
/* Components             */
/* ---------------------- */

#root {
  max-width: 30rem;
  border-radius: 100px;
}

.card {
  color: var(--clr-text-dark);
  background-color: var(--clr-background-light);
  border-radius: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
  margin: 0px 1.2rem 1.2rem 1.2rem;
}

.container {
  padding: 0 2em 2em 2em;
}

/* INFO */

.main--section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.info--photo {
  border-radius: 10px 10px 0 0;
}

.info--fullname {
  margin-top: 2rem;
  font-size: 1.6rem;
  padding: 0px 1rem 0px 1rem;
}

.info--role {
  font-weight: 500;
  color: var(--clr-text-grey);
  margin-top: 0.4rem;
  font-size: 0.8rem;
}

.info--contact {
  padding: 1rem;
  margin-top: 2rem;
  width: 100%;
  color: var(--clr-text-light);
}

.info--saveContact {
  text-decoration: none;
  color: var(--clr-button-color);
  background: var(--clr-button-bg);
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 1rem 1.5rem;
  cursor: pointer;
  line-height: 0;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
  gap: 5px;
}

.info--saveContact p {
  line-height: 1.5;
  font-size: 1rem;
}

.info--icon {
  width: 1.5rem;
  height: 1.5rem;
}

/* ABOUT */

.about {
  margin-top: 2em;
}
.about--title {
  margin-bottom: 0.5em;
}

/* INTERESTS */
.interests {
  margin-top: 2em;
}
.interests--title {
  margin-bottom: 0.5em;
}

/* FEATURED */

.featured {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2rem 0 0;
  width: 100%;
  padding: 1rem;
}

.featured--title {
  font-weight: bold;
  text-align: center;
  font-size: 1.3rem;
  padding: 1rem 0;
}

.featured--media {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  border-radius: 1rem;
  margin-top: 1rem;
}

.featured--mediaTitle {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.featured--mediaSub {
  font-size: 0.9rem;
  margin: 0.8rem;
}

.featured--mediaInfo {
  padding: 1rem;
  font-size: 0.9rem;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.button--mediaBtn {
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin: 1rem 0 0.5rem;
  border-radius: 10px;
  letter-spacing: 1px;
  padding: 1rem 1.5rem;
  background-color: var(--clr-button-bg);
  color: var(--clr-text-light);
}

/* FOOTER */

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1em 2em;
  background-color: var(--clr-background-darker);
  border-radius: 0px 0px 10px 10px;
  color: var(--clr-text-light);
  font-size: 0.8rem;
  margin-top: 2rem;
}

.footer a {
  color: var(--clr-text-light);
  margin-left: 3px;
}

.footer--icon {
  height: 25px;
  color: var(--clr-social-icons);
}

.footer--icon:hover {
  filter: brightness(120%);
}
