.modal--view-section {
    width: 100%;
    padding: .6rem;
}

.modal--title {
    text-align: center;
    font-size: 1rem;
    padding: 1rem;
}

.modal--view-section > div {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 0.7rem 1.2rem;
}

.copy--icon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background: var(--clr-button-bg);
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
}

.modal--view-icon {
    cursor: pointer;
    border-radius: 100%;
    line-height: 0;
    color: #dddddd;
}

.copy--icon p {
    font-size: .8rem;
}


.qr--item{
    margin: 1rem 2rem 2rem;
    padding: 2rem;
    background: #fff;
    border-radius: 0.5rem;
}

.qr--title,
.qr--text {
    margin: 0 2rem 0.5rem;
    text-align: center;
    color: #fff !important;
}

.qr--title {
    font-size: 1.5rem;
}

.qr--text {
    font-size: .9rem;
}